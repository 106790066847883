import { NgModule } from '@angular/core';
import { AppBarModule } from './app-bar';
import { MenuPanelModule } from './menu-panel';
import { DialogErrorModule } from './dialog-error';
import { PopErrorModule } from './pop-error';
import { SnackErrorModule } from '@zerops/zui/snack-error';
import { SnackSuccessModule } from '@zerops/zui/snack-success';
import { SnackWarningModule } from '@zerops/zui/snack-warning';
import { SnackInfoModule } from '@zerops/zui/snack-info';
import { NotificationModule } from './notification';
import { NewServiceDialogModule } from './new-service-dialog';
import { DeletionWarningDialogModule } from './deletion-warning-dialog';
import { HttpRoutingAddDialogModule } from './http-routing-add-dialog';
import { HttpRoutingEditDialogModule } from './http-routing-edit-dialog';
import { PortRoutingAddDialogModule } from './port-routing-add-dialog';
import { PortRoutingEditDialogModule } from './port-routing-edit-dialog';
import { UserDataAddDialogModule } from './user-data-add-dialog';
import { ProjectEnvAddDialogModule } from './project-env-add-dialog';
import { ProjectEnvsEditDialogModule } from './project-envs-edit-dialog';
import { ProjectEnvEditDialogModule } from './project-env-edit-dialog';
import { UserDataEditDialogModule } from './user-data-edit-dialog';
import { ProjectCostLimitDialogModule } from './project-cost-limit-dialog';
import { TopUpDialogModule } from './top-up-dialog';
import { DeployShutdownDialogModule } from './deploy-shutdown-dialog';
import { AddonActivationDialogModule } from './addon-activation-dialog';
import { VersionWarningModule } from './version-warning';
import { LogDialogModule } from './log-dialog';
import { SyncDialogModule } from './sync-dialog';
import { BillingInfoEditDialogModule } from './billing-info-edit-dialog';
import { NotificationsPopModule } from './notifications-pop';
import { ProcessesPopModule } from './processes-pop';
import { EnvFormatDialogModule } from './env-format-dialog';
import { CopyCodeDialogModule } from './copy-code-dialog';
import { ImportExportDialogModule } from './import-export-dialog';
import { PipelineDetailDialogModule } from './pipeline-detail-dialog';
import { ObjectStoragesUsageModule } from './object-storages-usage';
import { RecurringTopUpConfigDialogModule } from './recurring-top-up-config-dialog/recurring-top-up-config-dialog.module';

@NgModule({
  exports: [
    AppBarModule,
    MenuPanelModule,
    DialogErrorModule,
    PopErrorModule,
    SnackErrorModule,
    SnackSuccessModule,
    SnackWarningModule,
    SnackInfoModule,
    VersionWarningModule,
    NotificationModule,
    NewServiceDialogModule,
    NotificationsPopModule,
    DeletionWarningDialogModule,
    HttpRoutingAddDialogModule,
    HttpRoutingEditDialogModule,
    PortRoutingAddDialogModule,
    ProjectCostLimitDialogModule,
    PortRoutingEditDialogModule,
    UserDataAddDialogModule,
    ProjectEnvAddDialogModule,
    UserDataEditDialogModule,
    ProjectEnvsEditDialogModule,
    ProjectEnvEditDialogModule,
    EnvFormatDialogModule,
    CopyCodeDialogModule,
    ImportExportDialogModule,
    DeployShutdownDialogModule,
    AddonActivationDialogModule,
    LogDialogModule,
    TopUpDialogModule,
    ProcessesPopModule,
    SyncDialogModule,
    BillingInfoEditDialogModule,
    PipelineDetailDialogModule,
    ObjectStoragesUsageModule,
    RecurringTopUpConfigDialogModule
  ]
})
export class FeatureModule {}
