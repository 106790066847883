import isArray from 'lodash-es/isArray';
import { OperatorFunction, Observable, MonoTypeOperatorFunction } from 'rxjs';
import { filter, tap, map, distinctUntilChanged, take } from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';
import { zefGo } from '@zerops/zef/ngrx-router';
import { differenceInSeconds } from 'date-fns/esm';

export function arrayify<T>(data: any | any[]): T[] {
  return isArray(data) ? data : [ data ];
}

export const firstAvailable = <A extends Observable<any>>() => {
  return (source$: A) => source$.pipe(
    filter((d) => !!d),
    take(1)
  ) as A;
};

export const toBoolDistinct = <T>(): OperatorFunction<T, boolean> => {
  return (input$) => input$.pipe(
    map((s) => !!s),
    distinctUntilChanged()
  );
};

export const anyTrue: OperatorFunction<[ boolean, boolean ], boolean> = map(([ a, b ]) => a || b);

export function log<T>(message?: any): OperatorFunction<T, T> {
  return function(source$: Observable<T>): Observable<T> {
    return source$.pipe(
      tap((e) => {
        if (message) {
          console.log(message, e);
        } else {
          console.log(e);
        }
      })
    );
  };
}

export function removeAtIndex(myArray: any[], indexToRemove: number) {
  return myArray.slice(0,indexToRemove).concat(myArray.slice(indexToRemove + 1));
}

export function chooseWeighted(results: any[], weights: number[]) {
  const num = Math.random();
  let s = 0;
  const lastIndex = weights.length - 1;
  for (let i = 0; i < lastIndex; ++i) {
    s += weights[i];
    if (num < s) {
      return results[i];
    }
  }
  return results[lastIndex];
}

export const distinctUntilNotEqual = (prev: string[], next: string[]) => {
  let prevLength: number | undefined;
  /**
   * It is necessary to differentiate an array and an object.
   */
  if (Array.isArray(prev)) {
    prevLength = prev.length;
  } else {
    prevLength = Object.keys(prev).length;
  }
  return !prevLength || (!!prevLength && isEqual(prev, next));
};

export const distinctUntilLengthIncrease = (prev: any[], next: any[]) => {
  return !prev?.length || !(prev?.length < next?.length);
};

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

/**
* Returns a random integer between min (inclusive) and max (inclusive).
* The value is no lower than min (or the next integer greater than min
* if min isn't an integer) and no greater than max (or the next integer
* lower than max if max isn't an integer).
* Using Math.round() will give you a non-uniform distribution!
*/
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
      );
    });
  });
}

const toParams = (query: string) => {
  const q = query.replace(/^\??\//, '');

  return q.split('&').reduce((values, param) => {
    const [key, value] = param.split('=');

    values[key] = value;

    return values;
  }, {});
};

const toQuery = (params: any, delimiter = '&') => {
  const keys = Object.keys(params);

  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[key]}`;

    if (index < keys.length - 1) {
      query += delimiter;
    }

    return query;
  }, '');
};

export class AuthPopupWindow {

  id: string;
  url: string;
  options: any;
  window: Window;
  promise: Promise<any>;

  private _iid: number;

  constructor(
    url: string,
    options = { height: 1000, width: 600 },
    id = 'zef-auth-window'
  ) {
    this.id = id;
    this.url = url;
    this.options = options;
  }

  static open(url: string, options: any, id: string) {
    const popup = new this(url, options, id);

    popup.open();
    popup.poll();

    return popup;
  }

  open() {
    const { url, id, options } = this;

    this.window = window.open(url, id, toQuery(options, ','));
  }

  close() {
    this.cancel();
    this.window.close();
  }

  poll() {
    this.promise = new Promise<any>((resolve, reject) => {
      this._iid = window.setInterval(() => {
        try {
          const popup = this.window;

          if (!popup || popup.closed !== false) {
            this.close();

            reject(new Error('The popup was closed'));

            return;
          }

          if (
            popup.location.href === this.url ||
            popup.location.pathname === 'blank'
          ) {
            return;
          }

          const params = toParams(popup.location.search.replace(/^\?/, ''));

          resolve(params);

          this.close();
        } catch (error) {
          console.warn('An error has been caught:', error);
        }
      }, 250);
    });
  }

  cancel() {
    if (this._iid) {
      window.clearInterval(this._iid);
      this._iid = null;
    }
  }

  then(...args: any[]) {
    return this.promise.then(...args);
  }

  catch(...args: any[]) {
    return this.promise.then(...args);
  }

}

export function extractBetween(beg: string, end: string) {
  const matcher = new RegExp(`${beg}(.*?)${end}`,'gm');
  const normalise = (str: string) => str.slice(beg.length,end.length*-1);
  return function(str: string) {
    return str.match(matcher).map(normalise);
  };
}

export function filterUntil<T>(predicate: (value: T) => boolean): MonoTypeOperatorFunction<T> {
  return observable => new Observable<T>((observer) => {
    let conditionMet = false;

    return observable.subscribe({
      next: (value) => {
        if (!conditionMet && predicate(value)) {
          conditionMet = true;
        }

        if (conditionMet) {
          observer.next(value);
        }
      },
      error: (err) => observer.error(err),
      complete: () => observer.complete()
    });
  });
}


export function extractRepoData(url: string) {
  if (!url) return undefined;

  let match: any;
  let type: 'GITHUB' | 'GITLAB';
  if (url.includes('github.com')) {
    type = 'GITHUB';
    match = url.match(
      /^https?:\/\/(www\.)?github.com\/(?<owner>[\w.-]+)\/(?<name>[\w.-]+)/
    );
  } else if (url.includes('gitlab.com')) {
    type = 'GITLAB';
    match = url.match(
      /^https?:\/\/(www\.)?gitlab.com\/(?<owner>[\w.-]+)\/(?<name>[\w.-]+)/
    );
  }

  if (!match || !(match.groups?.owner && match.groups?.name)) {
    return undefined;
  }

  return {
    type,
    owner: match.groups.owner,
    name: match.groups.name
  };
}

export function extractRepoPath(url: string) {
  const data = extractRepoData(url);
  if (!data) {
    return undefined;
  }

  return `${data.owner}/${data.name}`;
}

export function distinctUntilKeysChanged<T>(keys: (keyof T)[]) {
  return distinctUntilChanged((a: T, b: T) => {
    for (const key of keys) {
      if (a[key] !== b[key]) {
        return false;
      }
    }
    return true;
  });
}

export function adjustDateToMicroseconds(dateString: string) {

  /**
   * Eliminate the last Z letter (ZULU time zone) if it exists.
   */
  const [ valuePart ] = dateString.split('Z');
  /**
   * Get the part before after decimal point if it exists.
   */
  const [ beforeDecimal, afterDecimal = '' ] = valuePart.split('.');

  /**
   * If the part after decimal point has already 3 numbers,
   * do nothing, and return just the input.
   */
  if (afterDecimal.length === 3) {
    return dateString;
  }

  /**
   * Trim the part after a decimal point to only 3 numbers
   * if it is longer, or add zeros till the length of three.
   */
  const adjustedMilliseconds = afterDecimal.length > 3
    ? afterDecimal.slice(0, 3)
    : afterDecimal.padEnd(3, '0');

  /**
   * Concatenate the returned result.
   * Always add the last latter Z (ZULU time zone).
   */
  return `${beforeDecimal}.${adjustedMilliseconds}Z`;
}

export const navigateToParams = (params: any) => zefGo(
  [],
  (params as any),
  { queryParamsHandling: 'merge' }
);

export function calculateDurationInSeconds(startIso: Date, end: Date): number {
  const now = new Date();
  return differenceInSeconds(end ? end : now, startIso);
}

export function formatDurationFromMinutes(minutes: number, useShorthand: boolean): string {
  const totalSeconds = Math.round(minutes * 60);
  const hours = Math.floor(totalSeconds / 3600);
  const diffMinutes = Math.floor(totalSeconds / 60); // Total minutes
  const remainingMinutes = diffMinutes % 60; // Remaining minutes after accounting for hours
  const seconds = totalSeconds % 60; // Remaining seconds

  if (useShorthand) {
    let shorthandFormatted = '';
    if (hours > 0) {
      shorthandFormatted += `${hours}h `;
    }
    if (remainingMinutes > 0 || hours > 0) {
      shorthandFormatted += `${remainingMinutes}m `;
    }
    shorthandFormatted += `${seconds}s`;

    return shorthandFormatted.trim();
  } else {
    // Long format with special handling for singular "sec"
    const formatted = hours > 0 ? `${hours} hours ` : '';
    const minutesPart = remainingMinutes > 0 ? `${remainingMinutes} minutes ` : '';
    const secondsPart = seconds === 1 ? `${seconds} sec` : `${seconds} secs`;

    return `${formatted}${minutesPart}${secondsPart}`.trim();
  }
}

export function formatDurationFromSeconds(seconds: number, useShorthand: boolean): string {
  const totalSeconds = Math.round(seconds); // Now directly using seconds
  const hours = Math.floor(totalSeconds / 3600);
  const remainingMinutes = Math.floor((totalSeconds % 3600) / 60); // Calculate remaining minutes after accounting for hours
  const remainingSeconds = totalSeconds % 60; // Remaining seconds after accounting for minutes

  if (useShorthand) {
    let shorthandFormatted = '';
    if (hours > 0) {
      shorthandFormatted += `${hours}h `;
    }
    if (remainingMinutes > 0 || hours > 0) {
      shorthandFormatted += `${remainingMinutes}m `;
    }
    shorthandFormatted += `${remainingSeconds}s`;

    return shorthandFormatted.trim();
  } else {
    // Long format with special handling for singular "sec"
    const formatted = hours > 0 ? `${hours} hours ` : '';
    const minutesPart = remainingMinutes > 0 ? `${remainingMinutes} minutes ` : '';
    const secondsPart = remainingSeconds === 1 ? `${remainingSeconds} sec` : `${remainingSeconds} secs`;

    return `${formatted}${minutesPart}${secondsPart}`.trim();
  }
}

export const envContentNeedsQuotes = (content: string) => {
  const mustQuote = [
    content.includes('\n'),
    content.includes('"'),
    content.startsWith('#'),
    /^\s|\s$/.test(content)
  ];

  return mustQuote.some(rule => rule);
};

export const escapeEnvContent = (content: string) => {
  return envContentNeedsQuotes(content)
    ? `"${content.replace(/([\\"])/g, '\\$1')}"`
    : content;
}
